
import { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation,Link } from 'react-router-dom'
import { ModalBox,InputBox } from '../../components'
import axios from 'axios'
import { validate } from '@/utils'
import { message } from 'antd'

import './layout.scss'


function CheckSeason({className}){

    const [status,setStatus] = useState(false)
    const [value,setValue] = useState('')
    const [data,setData] = useState({})
    // 0x29f22976680CB836c5E88a5787587DAA6De15D26
    // 0xdd3e53c2813ca30304d34a7146076833b38451bd
    // 0xc22a3e7ed61660ab7badc665a9d89d6b04e85080

    const showDialog = ()=>{
        setStatus(false)
    }

    const getPoint = ()=>{

        if(!validate.isContractAddr(value)){
            message.error({
                content: "Enter your EVM address",
            })
            return;
        }

        axios({
            method: 'post',
            url: 'https://api.quantlytica.com/api/dreward/queryuserpoint.do',
            data: `p=${JSON.stringify({"useraddr": value })}`
        }).then(function (response) {
            try {
                console.log(response.data.info.f_remark)
                const remark = response.data.info.f_remark
                const qtlx = remark ? JSON.parse(response.data.info.f_remark || "{}") : {QTLX: "0"}
                setData({QTLX: qtlx.QTLX || "0"})
            } catch (error) {
                message.error({
                    content: "Error.",
                })
            }
        }).catch(function (error) {
            message.error({
                content: "Error",
            })
        });
    }

    return (
        <>
            <div className={`flex align_center justify_center br_12 py_12 px_24 btn_b_2 fs_14 cursor ${className}`} onClick={_=>setStatus(true)}>
                <span className='zindex_0'>Check Season 1</span>
            </div>
            <ModalBox
                bodyClass="br_24"
                open={status}
                width={720}
                maskClosable={false}
                destroyOnClose={true}
                centered
                closeNative={true}
                onCancel={()=>showDialog(false)}
                footer={<span></span>}
            >
                <div className='checkSeasonBox flex column gap_24'>
                    <div className='formTitle py_12 b_g_1 br_24 text_center fw_700'>Check Your QTLX Allocation</div>
                    <div className='checkInput py_12'>
                        <div className='text_3 py_12 fw_600 fs_14'>EVM Address</div>
                        <div className='checkInput_1 b_g_4 br_16 fw_700 fs_16'><InputBox value={value} placeholder="Enter your EVM address" onChange={setValue}  /></div>
                        <div className='text_3 py_12 fw_600 fs_14 mt_24'>Season 1 Allocation</div>
                        <div className='checkInput_2 px_24 b_g_1_1 br_16 flex justify_between align_center text_2'>
                            <span className='fw_700 fs_16'>QTLX Amount</span>
                            <div className='flex align_center'>
                                <img style={{width: "18px"}} src='/images/icon/qcoin.png' />
                                <span className='text_1 fw_600 ml_8'>{data?.QTLX||"--"}</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex column gap_8'>
                        <div className='grid g_r_2 gap_24'>
                            <div className={`checkBtn flex align_center justify_center py_12 px_24 btn_b_2 cursor`} onClick={getPoint}>
                                <span className='zindex_0 text_g_1 fw_700'>Check</span>
                            </div>
                            <div className={`checkBtn flex align_center justify_center py_12 px_24 btn_b_2 opacity_3 cursor`} onClick={_=>showDialog(false)}>
                                <span className='zindex_0 text_g_1 fw_700'>Cancel</span>
                            </div>
                        </div>
                        <a href='https://docs.quantlytica.com/reward-program/season-1-completed' target='_blank' className='fs_12 text_3 text_center text_underline'>How is Season 1 allocation calculated?</a>
                    </div>
                </div>


            </ModalBox>
        </>
        
    )
}


function BitgetForm({className}){

    const location = useLocation()

    const [loading,setLoading] = useState(false)
    const [status,setStatus] = useState(false)
    const [form,setForm] = useState({
        bitgetuid: '',
        useraddr: '',
        evmaddr: '',
        tgname: '',
        xhandle: '',
        email: '',
    })

    // useLayoutEffect(()=>{
    //     if(location.pathname==="/tge"){
    //         setStatus(true)
    //     }
    // },[])


    const submitForm = ()=>{

        
        if(form.bitgetuid.length<1){
            message.error({
                content: "Bitget UID can not be empty",
            })
            return;
        }

        if(!validate.isNumber(form.bitgetuid)){
            message.error({
                content: "Wrong Bitget UID format",
            })
            return;
        }

        if(form.useraddr.length<1){
            message.error({
                content: "Bitget $QTLX deposit address can not be empty",
            })
            return;
        }

        if(!validate.isContractAddr(form.useraddr)){
            message.error({
                content: "Wrong Bitget $QTLX deposit address format",
            })
            return;
        }

        if(!validate.isContractAddr(form.evmaddr) && form.tgname.length===0 && form.xhandle.length===0){
            message.error({
                content: "Please provide at least one of them: On-chain Address / Telegram Username / X (Twitter) Handle.",
            })
            return;
        }

        setLoading(true)
        axios({
            method: 'post',
            url: 'https://api.quantlytica.com/api/dapp/adduserinfo.do',
            data: `p=${JSON.stringify(form)}`
        }).then(function (response) {
            message.success({
                content: "Successful",
            })
            reset()
            setLoading(false)
            setStatus(false)
            
        }).catch(function (error) {
            setLoading(false)
            message.error({
                content: "Error",
            })
        });
    }

    const reset = ()=>{
        setForm({
            bitgetuid: '',
            useraddr: '',
            evmaddr: '',
            tgname: '',
            xhandle: '',
            email: '',
        })
    }

    return (
        <Link to="/tge" className={`flex align_center justify_center br_12 py_12 px_24 btn_b_2 fs_14 cursor ${className}`}>
            <span className='zindex_0'>Bitget X $QTLX</span>
        </Link>
        // <>
        //     <div className={`flex align_center justify_center br_12 py_12 px_24 btn_b_2 fs_14 cursor ${className}`} onClick={_=>setStatus(true)}>
        //         <span className='zindex_0'>Bitget X $QTLX</span>
        //     </div>
        //     <ModalBox
        //         bodyClass="br_24"
        //         open={status}
        //         width={720}
        //         maskClosable={false}
        //         destroyOnClose={true}
        //         centered
        //         closeNative={true}
        //         onCancel={()=>setStatus(false)}
        //         footer={<span></span>}
        //     >
        //         <div className='checkSeasonBox flex column gap_24'>
        //             <div className='formTitle py_12 b_g_1 br_24 text_center fw_700'>Bitget X $QTLX Airdrop Claim</div>
        //             {/* <div>
        //                 <div className='b_g_1 br_24 py_12'>
        //                     <div className='formTitle btn_c_2 fw_700 text_center'>Bitget X $QTLX Airdrop Claim</div>
        //                     <div className='b_g_3 opacity_6 br_12 btn_c_1 px_24 py_12'>Sep 5th - 11th: 0 Gas Fee & Get 50 $QTLX First 2000 participants will receive double rewards!</div>
        //                 </div>
        //             </div> */}
                    
                    
        //             <div className='checkInput py_12 flex column gap_24'>

        //                 <div className='opacity_9 br_24 text_1 fs_16_14 fw_400'>
        //                     Sep 5th - 11th: 0 Gas Fee & Get 50 $QTLX <br />
        //                     First 2000 participants will receive double rewards!
        //                 </div>
        //                 <div className='bd_b bd_c_1 opacity_4 mb_12'></div>

        //                 <div className='flex column gap_4'>
        //                     <div className='flex justify_between align_end'>
        //                         <div className='text_1 fw_500 fs_14'>* Bitget UID</div>
        //                         {/* <a className={`cursor text_1`}>Register Bitget</a> */}
        //                         <a href="https://bitget.onelink.me/XqvW?af_xp=custom&pid=Quantlytica" target='_blank' className={`checkBtn flex align_center justify_center py_2 px_12 btn_b_2 cursor`}>
        //                             <span className='zindex_0 text_g_1 white_space_nowrap fw_500 fs_12'>Register Bitget</span>
        //                         </a>
        //                     </div>
        //                     <div className='py_4 b_g_4 br_16 fw_700 fs_16 flex justify_between'>
        //                         <InputBox value={form.bitgetuid} placeholder="Example. 7165763636" onChange={val=>setForm({...form,bitgetuid: val})}  />
        //                         {/* <div className='flex align_center pr_8'>
        //                             <div className={`checkBtn flex align_center justify_center py_4 px_12 btn_b_2 cursor`}>
        //                                 <span className='zindex_0 text_g_1 white_space_nowrap fw_500'>Register Bitget</span>
        //                             </div>
        //                         </div> */}
        //                     </div>
        //                 </div>
        //                 <div className='flex column gap_4'>
        //                     <div className='text_1 fw_500 fs_14'>* Bitget $QTLX Deposit Address</div>
        //                     <div className='py_4 b_g_4 br_16 fw_700 fs_16'>
        //                         <InputBox value={form.useraddr} placeholder="Example. 0xeBbD80F489BDa5B372A509149BF513604F305962" onChange={val=>setForm({...form,useraddr: val})}  />
        //                     </div>
        //                 </div>
        //                 <div className='flex column gap_4'>
        //                     <div className='text_3 fw_500 fs_14'>On-chain Address (To verify staking or $BIBI holdings)</div>
        //                     <div className='py_4 b_g_4 br_16 fw_700 fs_16'>
        //                         <InputBox value={form.evmaddr} placeholder="Example. 0xeBbD80F489BDa5B372A509149BF513604F305962" onChange={val=>setForm({...form,evmaddr: val})}  />
        //                     </div>
        //                 </div>
        //                 <div className='flex column gap_4'>
        //                     <div className='text_3 fw_500 fs_14'>Telegram Username (To verify community membership)</div>
        //                     <div className='py_4 b_g_4 br_16 fw_700 fs_16'>
        //                         <InputBox value={form.tgname} placeholder="" onChange={val=>setForm({...form,tgname: val})}  />
        //                     </div>
        //                 </div>
        //                 <div className='flex column gap_4'>
        //                     <div className='text_3 fw_500 fs_14'>X (Twitter) Handle (For lucky draw entry)</div>
        //                     <div className='py_4 b_g_4 br_16 fw_700 fs_16'>
        //                         <InputBox value={form.xhandle} placeholder="" onChange={val=>setForm({...form,xhandle: val})}  />
        //                     </div>
        //                 </div>
                        
        //             </div>
        //             <div className='flex column gap_24'>
        //                 <div className='grid g_r_2 gap_24'>
        //                     <div className={`checkBtn flex align_center justify_center py_12 px_24 btn_b_2 cursor`} onClick={submitForm}>
        //                         <span className='zindex_0 text_g_1 fw_700'>Submit</span>
        //                     </div>
        //                     <div className={`checkBtn flex align_center justify_center py_12 px_24 btn_b_2 opacity_3 cursor`} onClick={_=>setStatus(false)}>
        //                         <span className='zindex_0 text_g_1 fw_700'>Cancel</span>
        //                     </div>
        //                 </div>
        //                 <a href='https://docs.google.com/document/d/1WYWbknUxyia2aNTM9ODvFDM20zMBroWPGeAg2j_1UNU/edit?usp=sharing' target='_blank' 
        //                     className='fs_12 text_3 text_center text_underline'>How to find deposit address and UID in Bitget?</a>
        //             </div>
        //         </div>


        //     </ModalBox>
        // </>
        
    )
}


function LaunchApp({className}){
    return (
        <a className={`flex align_center justify_center br_12 py_12 px_24 btn_b_2 fs_14 ${className}`} href='https://dapp.quantlytica.com'>
            <span className='zindex_0'>Launch App</span>
        </a>
    )
}

function MultiIcon({className}){
    return (
        <div className={`flex align_center gap_12 ${className}`}>
            <a className='flex align_center justify_center br_12 pa_12 btn_b_2' href="https://docs.quantlytica.com/" target='_blank'>
                <img className='zindex_0' src='/images/icon/gitbook.svg' />
            </a>
            <a className='flex align_center justify_center br_12 pa_12 btn_b_2' href="https://twitter.com/quantlytica" target="_blank">
                <img className='zindex_0' src='/images/icon/twitter.svg' />
            </a>
            <a className='flex align_center justify_center br_12 pa_12 btn_b_2' href="https://t.me/quantlytica" target="_blank">
                <img className='zindex_0' src='/images/icon/telegram.svg' />
            </a>
        </div>
    )
}

export function HeaderIcon({}){
    return (
        <div className='flex align_center gap_24'>
            {/* <BitgetForm /> */}
            <LaunchApp />
            <MultiIcon className="hide_s" />
        </div>
    )
}

export function HomeIcon({className}){
    return (
        <div className={`flex align_center gap_24 mt_24`}>
            {/* <CheckSeason className="hide_m hide_l" /> */}
            {/* <LaunchApp className="hide_m hide_l hid" /> */}
            <MultiIcon className="hide_m hide_l" />
        </div>
    )
}